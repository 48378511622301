// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    offset: function () {
      return window.innerHeight - 200;
    },
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  //Animation Labels for Gravity Forms are going to top
  function formLabelAnimation() {
    $('form').on('focus', 'input, textarea, select', function () {
      $(this).closest('.gfield').addClass('active-field');
    });
    $('form').on('focusout', 'input, textarea, select', function () {
      // console.log(this.value);
      if (!this.value || this.value.indexOf('_)') >= 0) {
        $(this).closest('.gfield').removeClass('active-field');
      }
    });
  }
  formLabelAnimation();

  $(document).on('gform_post_render', function () {
    formLabelAnimation();
    $('form')
      .find('input')
      .each(function () {
        if (this.value) {
          $(this).closest('.gfield').addClass('active-field');
        }
      });
  });
  //End of Animation Labels for Gravity Forms are going to top

  //Animations for Elements
  ScrollOut({
    targets:
      '.js-footer-deco, .js-hero-head, .js-team-head, .js-team-info, .js-project-head, .js-mission-line',
    offset: 0,
    once: true,
  });
  //End of Animations for Elements

  //Team Memners Slick Slider
  let $teamSlider = $('.js-team-slider');
  let $progressBar = $('.our-team .progress');
  let $progressBarLabel = $('.our-team .slider__label');

  $teamSlider.on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      var calc = (nextSlide / (slick.slideCount - 1)) * 100;

      $progressBar
        .css('background-size', calc + '% 100%')
        .attr('aria-valuenow', calc);

      $progressBarLabel.text(calc + '% completed');
    }
  );
  $teamSlider.slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    speed: 900,
    customPaging: function (slider, i) {
      return (
        '<p class="slick-pagination-custom"><span class="big-orange-font">' +
        fullNumbers(i + 1) +
        '</span><span class="small-gray-font">' +
        '/' +
        fullNumbers(slider.slideCount) +
        '</span></p>'
      );
    },
  });

  function fullNumbers(number) {
    if (number < 9) {
      number = '0' + number;
    }
    return number;
  }
  //End of Team Memners Slick Slider
}); //End of Ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
